import { EnvInterface } from './env.interface'

export const environment: EnvInterface = {
  name: 'Development',
  production: false,
  type: 'dev',
  analytics: {
    logRocketAppId: '',
  },
  teleportExtensionId: 'fogbgogfedkknbbdiepecoacdoilemof',
  testingTeleportExtensionId: 'mnpbdpjbkkefodddcaoiciccbikfciog',
}
